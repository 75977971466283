import { Box, Flex, Heading, Image, Text } from 'rebass/styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import legalActsQuery from '../graphql/queries/getLegalActs.graphql';
import legalActThumb from '../images/akt-min.png';
import downloadIcon from '../images/download.svg';
import getDateInFormat from '../utils/getDateInFormat';
import Loader from './Loader';
import PaginatedView from './PaginatedView';

export default function LegalActs({ page }) {
  const { loading, data } = useQuery(legalActsQuery, {
    variables: { page },
  });
  if (loading && !data) {
    return (
      <Flex variant={'wrapper'}>
        <Loader />
      </Flex>
    );
  }
  return (
    <PaginatedView
      linkName={'akty-prawne'}
      pagesCount={data.getLegalActsWithLatestSource.pages}
      currentPage={page}
    >
      <Flex
        variant={'wrapper'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Heading
          marginLeft={20}
          mb={[25, 50]}
          fontSize={[24, 32]}
          fontFamily={'Oswald'}
          color={'text'}
          alignSelf={'flex-start'}
        >
          Akty prawne
        </Heading>
        <Flex marginTop={20} flexDirection={'column'}>
          {data.getLegalActsWithLatestSource.list.map((act, index) => {
            return (
              <Flex
                paddingY={3}
                key={act._id}
                flexDirection={'row'}
                alignItems={'center'}
                color={'text'}
                sx={{
                  borderBottom:
                    index === data.getLegalActsWithLatestSource.list.length - 1
                      ? 'none'
                      : '2px solid #707070',
                }}
              >
                <Flex
                  flex={2}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <Image src={legalActThumb} />
                  <Text mt={1}>{getDateInFormat(act.date)}</Text>
                </Flex>
                <Box flex={5}>{act.title}</Box>
                <Flex flex={1} justifyContent={'center'}>
                  {act.actSource && (
                    <a href={act.actSource} target="blank" download>
                      <Image color="primary" size={30} src={downloadIcon} />
                    </a>
                  )}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </PaginatedView>
  );
}
LegalActs.propTypes = {
  page: PropTypes.number,
};

LegalActs.defaultProps = {
  page: 0,
};
