import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass/styled-components';
import ReactPaginate from 'react-paginate';
import { navigate } from 'gatsby-link';

export default function PaginatedView(props) {
  function onPageChange(page) {
    if (props.currentPage !== page.selected) {
      navigate(`/${props.linkName}/${page.selected}`);
    }
    return null;
  }

  return (
    <>
      {props.children}
      <Flex className={'pagination-wrapper'}>
        <ReactPaginate
          pageCount={props.pagesCount}
          nextLabel={'Następna'}
          previousLabel={'Poprzednia'}
          onPageChange={onPageChange}
          disableInitialCallback
          activeClassName={'active-page'}
          activeLinkClassName={'active-page-link'}
          forcePage={props.currentPage}
        />
      </Flex>
    </>
  );
}

PaginatedView.propTypes = {
  children: PropTypes.element,
  linkName: PropTypes.string.isRequired,
  pagesCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
};

PaginatedView.defaultProps = {
  children: null,
};
