import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import LegalActs from '../../components/LegalActs';

const LegalActsPage = ({ page }) => {
  return (
    <Layout>
      <LegalActs
        page={Number.isNaN(Number(page)) || Number(page) < 0 ? 0 : Number(page)}
      />
    </Layout>
  );
};

LegalActsPage.propTypes = {
  page: PropTypes.string.isRequired,
};

LegalActsPage.defaultProps = {};

export default LegalActsPage;
